import React from 'react'
import { getBenefitsForPlan, getPlanByPriceId, getCoupon } from '../../shared/plans'

export default function PlanSalesSummary({priceId, couponId, user, country, userState}) {

  const plan = getPlanByPriceId(priceId);
  const coupon = getCoupon(couponId);
  const benefits = getBenefitsForPlan(plan);

  let taxRates = null;
  let taxRateCountries = null;
  let vat = null;

  if (user && user.hasOwnProperty('tax_rates')) {
    taxRates = user.tax_rates;
    taxRateCountries = Object.keys(user.tax_rates);
  }

  const styleOverride = userState && userState.match(/ranking_lists/) ? { marginTop: "37px", paddingBottom: '70px' } : {};

  if (!user) return null;

  return (
    <div className='signup-block sales-area'>
      <div id="plan-sales-summary">
        {/* <p className='sales-text' ng-bind-html='plan.sales.salesText'></p> */}
        <div className='subscription-summary-area' style={styleOverride}>
          <h3>Wordtracker <span className={`color-${plan.tier}`}>{ plan.tier.toUpperCase() }</span></h3>
          <div className='line-item'>
            <ul>
              { benefits && benefits.map((benefit, idx) => (
                <li key={idx}>
                  <i className="fa-solid fa-badge-check"></i>
                  { benefit.replaceAll('_', ' ') }
                </li>
              )) }
            </ul>
          </div>
          <div className='pricing'>
            { coupon && <>
              <span className='usual-total'>
                Usual price:&nbsp;
                <strong>${(plan.unit_amount_in_cents / 100).toFixed(2)}</strong>
              </span>
            </> }
            <span className='total'>
              { plan.period === 'monthly' && <>Monthly total:</> }
              { plan.period === 'annual' && <>Total (billed annually):</> }
              { plan.period === 'special' && <>Total today:</> }
              &nbsp;
              <strong>
                { coupon ?
                  <>${((plan.unit_amount_in_cents - coupon.discount) / 100).toFixed(2)}&nbsp;</> :
                  <>${(plan.unit_amount_in_cents / 100).toFixed(2)}&nbsp;</> }
                { vat && <><span>(inc. VAT)</span></> }
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
