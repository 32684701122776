import "./search.scss";
import { sendTriggerUpsellEvent } from "../shared/events.js";

var self = {};

class SearchController {

  constructor($location, $notifier, $f, $resultsStore, $timeout, $limits, $seeds, $exampleSeeds, $searchSettings, $upsells, $searchManager, UserService, $rootScope, cfpLoadingBar, $listManagementNotifier, $activeList, $activeColumns, $countryNames, countryNameFilter, $cookies, Modal, $tokenManager, $meta) {
    this.$location = $location;
    this.$notifier = $notifier;
    this.$f = $f;
    this.$resultsStore = $resultsStore;
    this.$timeout = $timeout;
    this.$limits = $limits;
    this.$seeds = $seeds;
    this.$exampleSeeds = $exampleSeeds;
    this.$searchSettings = $searchSettings;
    this.$upsells = $upsells;
    this.$searchManager = $searchManager;
    this.UserService = UserService;
    this.$rootScope = $rootScope;
    this.$tokenManager = $tokenManager;
    this.cfpLoadingBar = cfpLoadingBar;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$activeList = $activeList;
    this.$activeColumns = $activeColumns;
    this.$countryNames = $countryNames;
    this.countryNameFilter = countryNameFilter;
    this.user = UserService.user;
    this.placeholder = $exampleSeeds.pick();
    this.import = {};
    this.importedPlaceholder = "(Imported search terms)";
    this.domainPlaceholder = "(Keywords from By Domain tool)";
    this.$cookies = $cookies;
    this.Modal = Modal;
    this.$meta = $meta;
    
    this.clickstreamSources = ["google", "amazon", "youtube", "ebay"];

    this.import_message_match = "'Broad' selected. We'll only use your first 10 terms.";
    this.import_message_phrase = "'Phrase' selected. We'll only use your first 10 terms.";
    this.import_message_exact = "'We'll only use your first 500 terms.";

    this.dontShowNewDataNoticeAgain = true;

    self.$countryNames = $countryNames;

  }

  $onInit() {
    this.$activeColumns.loadDefault(this.$searchSettings.source, 'search');
    this.$searchManager.searched = !!(this.$resultsStore.main);
    this.searchIfQuery();
    this.trackAffiliateConversionIfSubscribed();
    this.refreshPageIfSubscribed();
    this.setQueryIfSearched();
    this.$rootScope.$on('showUpsell', this.handleTooManySearches.bind(this));
    this.$tokenManager.getToken();
    if (!window.document.title.match(/^(Here are the top keywords for|Search).*/)) {
      this.$meta.setTitle('Search | Wordtracker');
      this.$meta.setDescription('Wordtracker is a keyword research tool that gives you thousands of keywords for your market.');
    }
  }

  showOfferIfRequested() {
    let _params = this.$location.search();
    if (_params.offer) {
      this.$timeout(() => {
        this.Modal.new({
          component: 'signUpModal',
          dismissable: false,
        })
      }, 50);
    }
  }

  setKeywordsFromDomain() {
    if (this.$searchManager.searched) {
      this.isPreviousSearchProgrammatic = true;
      this.$searchSettings.previousSearch = this.$searchSettings.searchText;
      this.$searchSettings.searchText = this.domainPlaceholder;
      this.fromDomain = true;
      this.$searchSettings.setChanged(false);
    } else {
      this.$searchSettings.searchText = null;

    }
  }

  searchIfQuery() {
    let _params = this.$location.search();
    const { query, from_domain } = _params;
    if (query) {
      this.$searchSettings.searchText = query;
      this.search();
    }
    if (from_domain) {
      this.setKeywordsFromDomain();
    }
  }

  refreshPageIfSubscribed() {
    // Quick hack to get Google to register conversions
    let params = this.$location.search();
    if (params.subscribed === "true" && !params.complete) {
      this.$location.path("/search").search({subscribed: "true", complete: "true"});
    }
  }

  trackAffiliateConversionIfSubscribed() {
    let params = this.$location.search();
    let $FPROM = $FPROM || null;
    if ($FPROM && params.subscribed === "true" && !!this.UserService.user.email) {
      this.$timeout(() => {
        $FPROM.trackSignup({ email: this.UserService.user.email }, () => {
          // noop
        });
      }, 1000);
    }
  }

  setQueryIfSearched() {
    let _params = this.$location.search();
    let activeSeed = this.$searchSettings.activeSeed;
    if (!_params.query && activeSeed) {
      this.$location.addParams({query: activeSeed});
    }
  }

  sortedCountryName(code) {
    return self.$countryNames[code];
  }

  onEnter() {
    if (this.$searchManager.searched && !this.$searchSettings.changed) return false;
    this.searchButton();
  }

  searchButton() {
    if (this.$searchSettings.searchText == this.$searchSettings.previousSearch && this.isPreviousSearchProgrammatic) {
      this.searchBulk();
    } else {
      this.search();
    }
  };

  openModalIfExpired() {
    if (this.user.isExpiredUser) {
      sendTriggerUpsellEvent();
      return true;
    }
    return false;
  }

  search() {
    if (!this.$searchSettings.validateSearchText()) return this.$notifier.red(this.$searchSettings.seeds.errors[0]);
    if (this.sendToDomainIfUrl()) return false;
    if (this.openModalIfExpired()) return false;
    
    this.$searchSettings.activeSeed = this.$searchSettings.seeds.positive[0];
    this.$location.addParams({query: this.$searchSettings.activeSeed});
    this.$location.search('from_domain', null);
    this.$searchSettings.previousSearch = this.$searchSettings.searchText;
    this.$searchSettings.setChanged(false);
    this.$searchManager.search();
    this.$meta.setTitle(`Here are the top keywords for ${this.$searchSettings.activeSeed} powered by Wordtracker`);
    this.$meta.setDescription(`Wordtracker is a keyword research tool that gives you thousands of keywords for your market. So click on this result to see the keywords for ${this.$searchSettings.activeSeed}`);
    // performance.clearMarks();
    // performance.clearMeasures();
    // performance.mark("searchStart");
  };
  
  sendToDomainIfUrl() {
    let query = this.$searchSettings.searchText;
    if (this.isUrl(query)) {
      this.$location.path("/domain").search({query: encodeURIComponent(query), from_search: "true"});
      return true;
    }
    return false;
  }
  
  isUrl(string) {
    return !!string.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,20}(:[0-9]{1,5})?(\/.*)?$/)
  }
  
  searchBulk() {
    this.isPreviousSearchProgrammatic = true;
    this.$searchSettings.previousSearch = this.$searchSettings.searchText;
    this.$location.addParams({query: this.$searchSettings.activeSeed});
    this.$searchSettings.setChanged(false);
    this.$searchManager.searchBulkSeeds();
    // performance.clearMarks();
    // performance.clearMeasures();
    // performance.mark("searchStart");
  }

  constrainOptionsForSource(source) {
    if (!this.$limits.countries[source].available.includes(this.$searchSettings.searchParams.country)) this.$searchSettings.searchParams.country = 'US'; // Fallback to US if outside limits
    if (source == "google" && this.$searchSettings.searchParams.method === "phrase") this.$searchSettings.searchParams.method = "match"; // Fall back to match because phrase is not defined
    if (source != "google") this.$searchSettings.searchParams.state = null;
  }

  setCountry(country) {
    const countryCode = (typeof country === "string") ? country : country.value || country.code;
    if (countryCode != this.$searchSettings.searchParams.country) {
      this.$searchSettings.searchParams.country = countryCode;
      this.setSettingsChanged();
    }
  };

  setSource(source) {
    this.$searchSettings.source = source;
    this.constrainOptionsForSource(source);
    this.setSettingsChanged();
  };

  overwriteSearch(seed, callback) {
    this.$searchSettings.searchParams.seeds = [seed];
    this.$searchSettings.activeSeed = seed;
    this.$searchSettings.searchText = seed;
    this.$timeout(() => callback());
  };

  replaceSearch(seed) {
    this.overwriteSearch(seed, () => this.search());
  };

  setSettingsChanged(fromBox) {
    if (this.$searchManager.searched) this.$searchSettings.setChanged(true);
    if (fromBox && this.isPreviousSearchProgrammatic && this.$searchSettings.searchText != this.importedPlaceholder) {
      this.isPreviousSearchProgrammatic = false;
      this.$searchSettings.searchParams.plurals = true;
      this.$searchSettings.searchParams.method = 'match';
    }
  }

  onFocusSearchBox() {
    if (this.isPreviousSearchProgrammatic && (this.$searchSettings.searchText == this.importedPlaceholder || this.$searchSettings.searchText == this.domainPlaceholder)) {
      this.$searchSettings.searchText = "";
    }
  };

  onBlurSearchBox() {
    if (this.isPreviousSearchProgrammatic && this.$searchSettings.searchText == "") {
      this.$searchSettings.searchText = this.importedPlaceholder;
      this.$searchSettings.setChanged(false);
    } else if (!this.isPreviousSearchProgrammatic && this.$searchSettings.searchText == "" && this.$searchSettings.previousSearch) {
      this.$searchSettings.searchText = this.$searchSettings.previousSearch;
      this.$searchSettings.setChanged(false);
    }
  }
  

  handleTooManySearches() {
    if (this.user.isFreeUser) {
      this.$upsells.new('unlimited_searches');
    } else if (this.user.isTrialUser) {
      this.$upsells.new('really_unlimited_searches');
    }
  };

  newImport() {
    this.Modal.new({
      component: 'searchBulkModal',
      dismissable: true,
    }).then(this.createImport.bind(this));
  };

  createImport(keywords) {
    let firstSeed = keywords[0];
    let builtSeeds = this.$seeds(firstSeed, this.$searchSettings.source);
    this.$searchSettings.seeds = builtSeeds;
    this.$searchSettings.searchParams.seeds = keywords;
    this.$searchSettings.activeSeed = firstSeed;
    this.$searchSettings.searchText = this.importedPlaceholder;
    this.$searchSettings.searchParams.plurals = false;
    this.$searchSettings.searchParams.method = 'exact';
    this.searchBulk();
  };

}

SearchController.$inject = ['$location', '$notifier', '$f', '$resultsStore', '$timeout', '$limits', '$seeds', '$exampleSeeds', '$searchSettings', '$upsells', '$searchManager', 'UserService', '$rootScope', 'cfpLoadingBar', '$listManagementNotifier', '$activeList', '$activeColumns', '$countryNames', 'countryNameFilter', '$cookies', 'Modal', '$tokenManager', '$meta'];

export const search = {
  controller: SearchController,
  template: require('./search.html')
};
